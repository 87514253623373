/* eslint-disable */
import React from "react"
import { Link } from "gatsby"
import DashLayout from "../components/DashLayout"
import StyledHero from "../components/StyledHero"
import Paymethod from "../components/Home/Signup/Paymethod"
import {Elements, StripeProvider} from "react-stripe-elements"
import { graphql } from "gatsby"
import Keys from '../constants/keys'

let paymethod;

if(typeof window !== `undefined`) {
  paymethod = ({ data, location }) => {
    return (
      <DashLayout>
        <StyledHero img="none">
          <StripeProvider apiKey={Keys.stripe_publishable_key}><Elements>< Paymethod location={location} /></Elements></StripeProvider>
        </StyledHero>
      </DashLayout>
    )
  }
} else {
  paymethod = ({ data }) => {
    return (
      <DashLayout>
        <StyledHero img="none">
          This page cannot be rendered on the server
        </StyledHero>
      </DashLayout>
    )
  }
}

export const query = graphql`
  query {
    defaultBcg: file(relativePath: { eq: "defaultBcg.jpeg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default paymethod
