/* eslint-disable */
import React from 'react';
import {CardElement} from 'react-stripe-elements';
import styles from "../../../css/scael.module.css";

class Card extends React.Component {
  render() {
    return (
    <div className={styles.cardElementContainer}>
      <CardElement style={
      	{base: 
      		{fontSize: '1rem',
      		color:'rgba(255,255,255,1)',
      		textShadow:'0px 0px 5px rgba(255,255,255,1)',
      		'::placeholder':
      		{color:'rgba(255,255,255,1)'},
      	    ':hover::placeholder':
      	    {color:'rgba(0,0,0,1)'},
      	    ':hover':
      	    {color:'rgba(0,0,0,1)'},
      	    '::selection':
      	    {color:'rgba(255,255,255,1)'}}}}/>
    </div>
    );
  }
}

export default Card;