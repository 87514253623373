/* eslint-disable */
import React from 'react';
import Keys from '../../constants/keys';
import {render} from 'react-dom';
import {StripeProvider, Elements} from 'react-stripe-elements';

import RivetSubscribe from './RivetSubscribe';
import SubscribeForm from './SubscribeForm'

class App extends React.Component {
  constructor() {
    super();
    this.state = {stripe: null};
  }
  componentDidMount() {
    if (window.Stripe) {
      this.setState({stripe: window.Stripe(Keys.stripe_publishable_key)});
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({stripe: window.Stripe(Keys.stripe_publishable_key)});
      });
    }
  }
  render() {
    console.log(this.props);
    // this.state.stripe will either be null or a Stripe instance
    // depending on whether Stripe.js has loaded.
    return (
      <StripeProvider stripe={this.state.stripe}>
        <Elements>
          <SubscribeForm submitting={this.props.submitting} handleSubmit={this.props.handleSubmit} />
        </Elements>
      </StripeProvider>
    );
  }
}

export default App
