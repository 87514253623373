/* eslint-disable */
import React from 'react';
import styles from "../../../css/scael.module.css"

const Street = ({ children, onUpdate }) => {
    return (
    <div className={styles.scaelText}>
      <input name="street" placeholder="Billing Street Address" onChange={onUpdate} />
    </div>
    );
};

export default Street;
