/* eslint-disable */
import React from 'react';
import styles from "../../../css/scael.module.css"

const Submit = ({ children, submitting }) => {
    return (
    <div className={styles.buttonWrapper}>
      <input disabled={submitting} type="submit" name="submit" value="Submit" className={styles.scaelButton}/>
    </div>
    );
};

export default Submit;
