/* eslint-disable */
import React from 'react'
import {injectStripe} from 'react-stripe-elements'
import Name from './Fields/Name'
import Card from './Fields/Card'
import Street from './Fields/Street'
import City from './Fields/City'
import State from './Fields/State'
import Country from './Fields/Country'
import Email from './Fields/Email'
import Submit from './Fields/Submit'
import styles from "../../css/scael.module.css"
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';


class SubscribeForm extends React.Component {
  constructor() {
    super();
    this.state = {"country": "US"};
  }
  handleSubmit = (ev) => {
    console.log(ev);
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    console.log(ev);

    this.props.stripe.createToken({
      type: 'card',
      name: this.state.name,
      address_line1: this.state.street,
      address_city: this.state.city,
      address_state: this.state.state,
      address_country: this.state.country,
    }).then((token) => {
      console.log(token);
      if(token.error) {
        this.state.error_message = token.error.message;
        return
      }
      this.props.handleSubmit(token, this.state.email)
    });
  };

  updateState(field) {
    return (e) => {
      let value;
      if(e.target) {
        value = e.target.value;
      } else {
        value = e;
      }
      let state = {};
      state[field] = value
      this.setState(state)
    }
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className={styles.row1}>
        <Name onUpdate={this.updateState("name")} />
        <Email onUpdate={this.updateState("email")} />
        </div>
        <div className={styles.row2}>
        <Street onUpdate={this.updateState("street")} />
        <City onUpdate={this.updateState("city")} />
        </div>
        <div className={styles.row2}>
        <CountryDropdown valueType="short" value={this.state.country} onChange={this.updateState("country")} />
        <RegionDropdown valueType="short" countryValueType="short" country={this.state.country} value={this.state.state} onChange={this.updateState("state")} />
        </div>
        <div className={styles.row3}>
        <Card onUpdate={this.updateState("card")} />
        </div>
        <div className={styles.row4}>
        <Submit submitting={this.props.submitting}/>
        </div>
        <div className={styles.row5}>
          {this.state.error_message}
        </div>
      </form>
    );
  }
}

if(typeof window !== `undefined`) {
  SubscribeForm = injectStripe(SubscribeForm);
}

export default SubscribeForm;
