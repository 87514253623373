/* eslint-disable */
import React from "react"
import {useState} from "react"
import Title from "../../Title"
import styles from "../../../css/paymethod.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import {injectStripe} from "react-stripe-elements"
import App from "../../Stripe/App"
import Utils from "../../../utils"
import { navigate } from "gatsby"

let Paymethod = ({location}) => {
  let orgid = Utils.getQueryVariable(location, "org");
  let [error, setError] = useState("");
  let [submitting, setSubmitting] = useState(false);
  const handleSubmit = (stripe_token, email) => {
    if(submitting) { return }
    setSubmitting(true);
    fetch(`/api/org/${orgid}/billing`, {
      method: "POST",
      body: JSON.stringify({
        "email": email,
        "stripe_token": stripe_token.token.id,
      }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": Utils.getCookie('csrftoken'),
      }
    }).then((r) => r.json()).then((r) => {
      if(r.ok) {
        navigate("/update-plan/");
      } else {
        if (r.error == "stripe-token-missing") {
          setError("Unknown error processing payment information. Please try again.")
        } else if (r.error == "stripe-missing-payment") {
          setError("The payment method you have entered is not supported. Please try another method.")
        } else if (r.error == "stripe-checks-failed") {
          setError("Your payment method could not be authorized. Please check the information entered.")
        }
      }
    })
  }
  return (
    <section className={styles.paymethod}>
      <span className={styles.modalHeader}><Title title="Complete this form." /></span>
      <p className={styles.instructionsModal}>Enter new payment details here. Please note: You will not be immediately billed.</p>
      <div className={styles.center}>
        <App submitting={submitting} handleSubmit={handleSubmit} className={styles.app} />
      </div>
      <div className={styles.error}>{ error }</div>
    </section>
  )
}

if(typeof window !== `undefined`) {
  Paymethod = injectStripe(Paymethod);
} else {
  Paymethod = () => {
    return (
      <section>This page cannot be server-side rendered</section>
    )
  }
}

export default Paymethod;
