/* eslint-disable */
import React from 'react';
import styles from "../../../css/scael.module.css"
import { RegionDropdown } from 'react-country-region-selector';

let x = {state: "x"};
const State = ({ children, onUpdate }) => {
    const change = (val) => {
      x.state = val;
      onUpdate({target: {value: val}});
    }
    return (
      <div className={styles.scaelPicker}>
        <RegionDropdown valueType="short" />
      </div>
    );
};

export default State;
