/* eslint-disable */
import React from 'react';
import styles from "../../../css/scael.module.css"

const Name = ({ children, onUpdate }) => {
    return (
    <div className={styles.scaelText} >
      <input name="name" placeholder="Name on Card" onChange={onUpdate}/>
    </div>
    );
};

export default Name;
