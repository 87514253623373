/* eslint-disable */
import React from 'react';
import {Elements} from 'react-stripe-elements';

import InjectedSubscribeForm from './SubscribeForm';

class RivetSubscribe extends React.Component {
  render() {
    return (
      <Elements>
        <InjectedSubscribeForm />
      </Elements>
    );
  }
}

export default RivetSubscribe;